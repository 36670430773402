// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-contact-js": () => import("./../../../src/pages/order/contact.js" /* webpackChunkName: "component---src-pages-order-contact-js" */),
  "component---src-pages-order-index-js": () => import("./../../../src/pages/order/index.js" /* webpackChunkName: "component---src-pages-order-index-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-store-index-js": () => import("./../../../src/pages/store/index.js" /* webpackChunkName: "component---src-pages-store-index-js" */),
  "component---src-templates-product-template-product-template-js": () => import("./../../../src/templates/ProductTemplate/ProductTemplate.js" /* webpackChunkName: "component---src-templates-product-template-product-template-js" */)
}

