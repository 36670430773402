import React from 'react';
import { ProductContextProvider } from './src/context/ProductContext';
import { CartContextProvider } from './src/context/CartContext';
import 'styles/global.scss';

export const wrapRootElement = ({ element }) => (
  <ProductContextProvider>
    <CartContextProvider className="root">{element}</CartContextProvider>
  </ProductContextProvider>
);
