import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  {
    allContentfulBags {
      edges {
        node {
          id
          contentful_id
          title
          slug
          image {
            gatsbyImageData(width: 150, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

const defaultState = {
  products: [],
};

const ProductContext = React.createContext(defaultState);
export default ProductContext;

export function ProductContextProvider({ children }) {
  const { allContentfulBags } = useStaticQuery(query);

  return (
    <ProductContext.Provider
      value={{
        products: allContentfulBags?.edges?.map(({ node }) => node) || [],
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}
