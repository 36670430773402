import React, { useState } from 'react';
import { navigate } from 'gatsby';

const defaultState = {
  cart: {},
};

const CartContext = React.createContext(defaultState);
export default CartContext;

export function CartContextProvider({ children }) {
  const [checkout, setCheckout] = useState(
    JSON.parse(
      typeof window !== 'undefined'
        ? localStorage.getItem('sb5d.checkout')
        : null
    )
  );

  const [isCart, setIsCart] = useState(false);

  const removeLineItem = (id) => {
    if (typeof window !== 'undefined') {
      let cart = localStorage.getItem('sb5d.checkout');
      cart = cart ? JSON.parse(cart) : null;

      const newCart = cart?.filter((key) => key.id !== id);

      console.log('id', id);
      console.log('cart', cart);
      console.log('newCart', newCart);

      updateLineItem(newCart);
    }
  };

  const updateLineItem = (product) => {
    setCheckout(product);
    setIsCart(true);
    if (typeof window !== 'undefined') {
      localStorage.setItem('sb5d.checkout', JSON.stringify(product));
    }
    navigate('/order/');
  };

  const dismissSuccessfulOrder = () => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('sb5d.checkout');
      setCheckout(null);
    }
  };

  return (
    <CartContext.Provider
      value={{
        checkout,
        updateLineItem,
        removeLineItem,
        dismissSuccessfulOrder,
        isCart,
        setIsCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
